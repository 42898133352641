import React from "react"
// import { Link } from "gatsby"
import "./footer.css"

const footer = () => (
	<div className="text-white py-4 footer">
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-12">
						<span className="spanYear">
							© Copyright 2020 &nbsp;| Stompin Groundz
						</span>
					</div>
					<div className="col-md-6 col-sm-12 text-right">
						<span className="spanYear">Designed and Powered by </span>
						<a
							className="footer-link"
							href="http://webriq.com"
							target="_blank"
							alt="WebriQ"
							rel="noopener noreferrer"
						>
							WebriQ
						</a>
					</div>
				</div>
			</div>
		</footer>
	</div>
)

export default footer
