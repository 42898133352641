import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo.png"

export default () => (
	<div className="navigation">
		<div className="nav-brand-side">
			<img className="sidebar-logo" src={logo} alt="Stompin' Groundz" />
		</div>
		<Nav className="flex-column text-space text-uppercase">
			<Nav.Item>
				<Link to="/">Home</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">Menu</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">Events</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/">Contact Us</Link>
			</Nav.Item>
		</Nav>
	</div>
)
